<template>
<section class="categoryTable">
    <pageLoader v-if="showpageLoader" :loadingText="'Traitement des données en cours'"/>

    <div class="page-header">
      <h3 class="page-title">
        Catégories
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Produit</a></li>
          <li class="breadcrumb-item active" aria-current="page">Catégories</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Liste de toutes les catégories</h4>
            <div class="row">
              <div class="col-md-2">
                <button type="button" class="btn btn btn-gradient-success btn-fw btn-secondary" @click="addCategory()">Ajouter</button>
              </div>
              <div class="col-md-4 offset-md-6">
                <b-input-group>
                      <!-- <b-form-input placeholder="Recherche" v-if="activeSearch" v-model="search"></b-form-input> -->
                </b-input-group>
              </div>
            </div>
            <div v-if="isload" class="jumping-dots-loader">
                    <span></span>
                    <span></span>
                    <span></span>
            </div>
            <template v-else>
              <!-- card category -->
              <div class="row">
                <div class="col-md-6 col-sm-12" v-for="(item, item_id) in categories" :key="item_id">
                  <!-- 01FY46MX2EW85PP30KQ6S9BXG3 est l'ulid de la catégorie fermeture (utilisé pour les bloquages de date) -->
                  <div class="card" v-if="item.ulid !='01FY46MX2EW85PP30KQ6S9BXG3'">
                    <!-- EDIT / DELETE -->
                    <label title="Editer le produit" class="badge badge-info crud edit pointer"  style="z-index:1" @click="editCategory(item)"><i class="fa fa-pencil"></i></label>
                    <label title="Supprimer le produit" class="badge badge-danger crud delete pointer"  style="z-index:1" @click="showAlert(item)"><i class="fa fa-trash"></i></label>
                    <div class="card-body">
                        <div class="product-img-outer">
                            <!-- image -->
                            <template>
                              <picture v-b-modal="item.ulid" v-if="item.image">
                                <source :srcset="item.image.content.webpImage" type="image/webp">
                                <img class="category-image" :src="item.image.content.originalImage" alt="">
                              </picture>
                              <img v-else v-b-modal="item.ulid" class="category-image" src="https://fakeimg.pl/300x200"  alt="Card image">

                              <!-- modal image -->
                              <b-modal :id="item.ulid" size="lg" hide-footer hide-header>
                                <div class="d-flex justify-content-end">
                                  <add-image
                                  :modal="'addImageCategorie' + item.ulid "
                                  @saveImage="saveImage(item, $event['ulid'])"
                                  />
                                </div>
                                <picture v-b-modal="item.ulid" v-if="item.image">
                                  <source :srcset="item.image.content.webpImage" type="image/webp">
                                  <img :src="item.image.content.originalImage" alt="">
                                </picture>
                                <img v-else  class="product_image" src="https://fakeimg.pl/300x200"  alt="Card image">
                              </b-modal>
                            </template>
                        </div>
                        <!-- détails -->
                        <div>
                          <h4 class="mt-3" @click="showDetails(item)" style="cursor:pointer">{{item.category}}</h4>
                          <p>{{item.description}}</p>
                          <!-- <p>{{item.services.length}}</p>
                          <p>{{item.products.length}}</p> -->

                        </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- tableau category -->
              <!-- <b-table 
              class="table table-hover"
              :tbody-td-class="'limit-text'"
              :items="categories"
              :fields="fields"
              id="table-list" 
              responsive
              :filter="search"
              >
              
              <template class="image-category" #cell(image)="data" >
                <picture v-b-modal="data.item.ulid" v-if="data.item.image">
                  <source :srcset="data.item.image.content.webpImage" type="image/webp">
                  <img :src="data.item.image.content.originalImage" alt="">
                </picture>
                <img v-else v-b-modal="data.item.ulid" class="product_image" src="https://fakeimg.pl/300x200"  alt="Card image">
                
                <b-modal :id="data.item.ulid" size="lg" hide-footer hide-header>
                  <div class="d-flex justify-content-end">
                    <add-image
                    :modal="'addImageCategorie' + data.item.ulid "
                    @saveImage="saveImage(data.item, $event['ulid'])"
                    />
                  </div>
                  <picture v-b-modal="data.item.ulid" v-if="data.item.image">
                    <source :srcset="data.item.image.content.webpImage" type="image/webp">
                    <img :src="data.item.image.content.originalImage" alt="">
                  </picture>
                  <img v-else  class="product_image" src="https://fakeimg.pl/300x200"  alt="Card image">
                </b-modal>
              </template>
              
              <template #cell(action)="row">
                <button @click="editCategory(row)" type="button" class="btn btn-social-icon btn-outline-info"><i class="fa fa-pencil"></i></button>
                <button @click="categoryInfo(row)" type="button" class="btn btn-social-icon btn-outline-info"><i class="mdi mdi-eye"></i></button>
                <button @click="showAlert(row)" type="button" class="btn btn-social-icon btn-outline-danger"><i class="mdi mdi-delete"></i></button>
              </template>
            </b-table> -->
            </template>
            
            <!-- <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right">
            </b-pagination> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import addImage from '../../components/functions/add-image.vue'
import pageLoader from '../../components/pageLoader.vue'

const axios= require ('axios').default
export default {
  name: '',
  components: {
    addImage,
    pageLoader
  },
  data () {
    return  {
      search:'',
      isload:true,
      showpageLoader:false,
      fields: [
        // { key: 'ulid', label:'ulid', sortable: true},
        { key:'image', label:'image'},
        { key: 'category', label:'Nom de la catégorie', sortable: true},
        { key: 'description', label:'description', sortable: true},
        // { key: 'datepublished', label:'date d\'ajout', sortable: true},
        { key: 'action', label:''}
      ],
      categories:[],
      categories_display:[]
    }
  },
  computed:{
  },
  watch: {
    todos: {
      handler () {
        localStorage.setItem('todos', JSON.stringify(this.todos))
      },
      deep: true
    } 
  },
  async mounted () {
    // global categories
    this.getCategory()
  },
  methods: {
    async getCategory() {
     await axios.get('categories')
     .then(resCategories=> {
       this.categories= resCategories.data.result
       this.categories_display = this.categories
       this.isload = false

      //  console.log(this.categories);
       })
     .catch(errCategories=> console.log(errCategories))
    },
    findCategorie()
        {
            this.categories_display=null
            const regex = new RegExp(this.search.toLowerCase());
            let newcategorie = []
            this.categories.forEach(element => {
                if(regex.test(element.category.toLowerCase())){
                    newcategorie.push(element)
                }
            })
            this.categories_display = newcategorie
    },
    categoryInfo(category) {
      this.isload = true
      this.$router.push (
        {
          name: 'categoryInfo',
          params: {
            ulidCategory:category.item.ulid
          }
        }
      )
    },
    addCategory() {
      this.$router.push({
        name: 'addCategory'
      })
    },
    editCategory(item) {
      this.isload = true
      this.$router.push (
        {
          name: 'editCategory',
          params: {
            category: item,
            ulid:item.ulid
          }
        }
      )
    },
    showAlert (item) {
        this.$swal({
        title: 'Êtes-vous sûr?',
        text: 'Voulez-vous supprimer ce produit?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, Supprimer',
        cancelButtonText: 'Annuler',
      })
      .then((result) => {
        if (result.value) {
          this.isload = true
          axios.delete(`categories/${item.ulid}`, {
            headers: {
              'X-AUTH-TOKEN': localStorage.getItem('token')
            }
          })
          .then(()=> {
          this.isload = false

            this.$swal(
              'Supprimé!',
              'La catégorie a bien été supprimée',
              'success'
            )
            this.getCategory()
          })
          .catch(err => console.log(err))
          
        }
      })
    },
    saveImage(category,ulidImage){
      this.showpageLoader = true
       axios.put(`categories/${category.ulid}`, {
           image: ulidImage,
          },
          {
          headers: {
              'X-AUTH-TOKEN': localStorage.getItem('token')
          }
      })
          .then(()=> {
            window.location.reload()
          })
          .catch(err => console.log ('errorPUT', err))
    }
  }
}
</script>

<style scoped>
.modal img {
  width: 100%;
}
.limit-text tr{
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 6rem; */
  display: block !important;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
::v-deep td {
  /* display: block !important; */
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* CRUD */
.crud.edit{
height: 40px;
width: 40px;
border-radius: 50%;
position: absolute;
right: 80px;
top: 20px;
padding-top: 10px;
padding-left: 13px;
font-size: 17px;
}
.crud.delete{
height: 40px;
width: 40px;
border-radius: 50%;
position: absolute;
right: 36px;
top: 20px;
padding-top: 10px;
padding-left: 13px;
font-size: 17px;
}
.card .crud {
    /* visibility: hidden; */
    display: none
}
.card:hover>.crud {
    /* visibility: visible; */
    display: inherit;
}
/* image */
.category-image {
  width:100%;
  max-height: 10rem;
  object-fit: cover;
}
</style>